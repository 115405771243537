import STRINGS from "../../utils/strings";

const ValueWithFallback = (value, useNA = null) => {
  let defaultString = STRINGS.NULL;

  if (useNA && useNA === true) {
    defaultString = STRINGS.NA;
  }
  if (value === "0" || value === 0) {
    return value.toString();
  }
  if (!value || value === undefined || value === null) {
    return defaultString;
  }
  let sValue = value.toString();
  let stringValue = sValue.trim();

  if (stringValue === " " || stringValue === "") {
    return defaultString;
  }

  return stringValue;
};

export default ValueWithFallback;
