import BaseField from "../../ui/BaseField";
import ValueWithFallback from "../../ui/ValueWithFallback";
import MaxPainLevelModel from "./MaxPainLevelModel";

const MaxPainLevel = ({
  fieldName,
  painLevel,
  bodyPart,
  flag,
  clock,
}) => {
  return (
    <BaseField
      id="SB-Max-Pain-Level"
      testId="SB-Max-Pain-Level"
      fieldName={fieldName}
      label={"Max Pain Level"}
      value={painLevel + " " + bodyPart}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(MaxPainLevelModel(bodyPart, painLevel))}
    </BaseField>
  );
};

export default MaxPainLevel;
