import { Cancel } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../../../context/DataContext";
import CaseSummaryContext from "../../../context/CaseSummaryContext";
import axios from "../../../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import ResponseDisplay from "../../../components/ResponseDisplay";

export default function PoliceReportCheckbox({ caseObj }) {
  const { accessToken } = useContext(DataContext);
  const {
    caseDetail,
    caseSummaryTrigger,
    setCaseSummaryTrigger,
    documentTrigger,
  } = useContext(CaseSummaryContext);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportReceived, setReportReceived] = useState(
    !caseDetail ? false : caseDetail.police_report_received
  );

  const params = useParams();

  useEffect(() => {
    axios
      .get(`/api/documents/?limit=1&case=${params.id}&document_type=7`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const isReceived = response.data.results.length > 0 ? true : false;
        setReportReceived(isReceived);
        setLoading(false);
        if (reportReceived !== caseDetail.police_report_received) {
          setLoading(true);
          submitData(isReceived);
        } else {
          return;
        }
        return response;
      })
      .catch((response) => {
        handleApiError(response);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [accessToken, params.id, documentTrigger]);

  const handleApiError = (error) => {
    setLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr);
    setResponseSuccess(false);
    setReportReceived(caseDetail.police_report_received);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 60000);
    return error;
  };

  const handleApiSuccess = (response, valueName) => {
    setLoading(false);
    const res = response;
    setCaseSummaryTrigger(!caseSummaryTrigger);
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const submitData = (value) => {
    setLoading(true);
    const postData = {
      police_report_received: value,
    };

    axios
      .patch(`/api/case/${caseObj.pk}/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, "Police Report");
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  return (
    <div>
      <div className="flex items-center">
        <FormControlLabel
          name="police_report_received"
          value={reportReceived}
          control={<Checkbox color="success" checked={reportReceived} />}
          label={<Typography variant="body2">Police Report</Typography>}
        />
        {loading ? (
          <CircularProgress color="secondary" size={25} />
        ) : responseSuccess === false ? (
          <Cancel className="text-red-600" />
        ) : (
          ""
        )}
      </div>
      <ResponseDisplay
        responseBreakdown={responseBreakdown}
        responseSuccess={responseSuccess}
      />
    </div>
  );
}
