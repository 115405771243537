import React from "react";
import { IconButton, styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "../utils/TooltipStyling";

export default function ActionButton({ title, icon, action }) {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  return (
    <HtmlInfoTooltip title={title}>
      <IconButton
        size="small"
        onClick={() => action()}
        className={`w-[40px] h-[40px] block hover:bg-sky-600 hover:text-white border-none text-sky-600`}
      >
        <div>{icon}</div>
      </IconButton>
    </HtmlInfoTooltip>
  );
}
