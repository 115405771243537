import { ArrowDownward } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

export default function ShowMoreButtonAutoComp({
  showMoreVisible,
  setResLimit,
  resLimit,
  showMoreAmt,
  customTitle,
}) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {" "}
      <Tooltip
        title={
          showMoreVisible
            ? `Load more results ${customTitle ? "for " + customTitle : ""}`
            : `All results loaded  ${customTitle ? "for " + customTitle : ""}`
        }
      >
        <span>
          {" "}
          <IconButton
            disabled={showMoreVisible ? false : true}
            size="small"
            onClick={() => setResLimit(+resLimit + showMoreAmt)}
          >
            <ArrowDownward className="text-[18px]" />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}
