import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const ProvidersOutNetwork = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-Providers-Out"
      testId="SB-Providers-Out"
      fieldName={fieldName}
      label={"Out-of-Network"}
      value={value}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(value, true)}
    </BaseField>
  );
};

export default ProvidersOutNetwork;
