import CustomMenuList from './CustomMenuList';
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { bindPopover } from "material-ui-popup-state";

export default function CustomPopUpMenu({menuItems, popupState})
{
    return(
        <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{ 
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical:"top",
                horizontal:"left",
            }}
        >
            <CustomMenuList menuItems={menuItems}/>
        </HoverPopover>
    );
}