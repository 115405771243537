import InfoToolTip from "../InfoToolTip";
import { Square } from "@mui/icons-material";

const ClockTooltip = ({ fieldName, title, desc, legend, children }) => {
  return (
    <div id={"Clock-ToolTip-" + fieldName} data-testid={"Clock-ToolTip-" + fieldName}>
      <InfoToolTip
        itemList={legend}
        headerTitle={title}
        desc={desc}
        icon={<Square fontSize="inherit" />}
        id={fieldName + "_tooltip"}
      >
      <span className="cursor-default">{children}</span>
      </InfoToolTip>
    </div>
  );
};

export default ClockTooltip;
