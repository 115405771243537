import React from "react";
import indicatorColors from "../utils/indicatorColors";
import CaseListToolTip from "../ui/CaseListToolTip";
import { useNavigate } from "react-router-dom";

import { Group } from "@mui/icons-material";

const RelatedCases = ({ relatedCases }) => {
  const navigate = useNavigate();

  const navigateToDetail = (caseId) => {
    navigate(`/client-detail/${caseId}`)
  };

  return (
    <div id="Do-Not-Contact" data-test-id="Do-Not-Contact">
      <span style={{ color: indicatorColors.green }}>
        <CaseListToolTip
          caseList={relatedCases}
          headerTitle={"Related Cases"}
          navigateToCaseDetail={navigateToDetail}
        >
          <Group fontSize="inherit" />
        </CaseListToolTip>
      </span>
    </div>
  );
};
export default RelatedCases;
