import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;

const bodyPartValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  let trimmedValue = value.trim();
  if (trimmedValue === "") {
    return emptyString;
  }
  return trimmedValue;
};

const painValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  
  if(typeof value === 'string'){
    let trimmedValue = value.trim();
    if (trimmedValue === "") {
      return emptyString;
    }
    return trimmedValue;
  }
  return value.toString();
};

const separator = (bodyPart, pain) => {
  if (bodyPartValue(bodyPart) === "" || painValue(pain) === "") {
    return emptyString;
  }
  return " ";
};

const MaxPainLevelModel = (bodyPart, painLevel) => {
  return bodyPartValue(bodyPart) + separator(bodyPart, painLevel) + painValue(painLevel);
}

export default MaxPainLevelModel;