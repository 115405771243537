import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import CaseSummaryContext from "../../../context/CaseSummaryContext";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import ModalShell from "../../../../../../../../global/ModalShell";
import FormSubmitButton from "../../../../../../../../global/FormComponents/FormSubmitButton";
import axios from "../../../../../../../../api/axios";
import ResponseDisplay from "../../../components/ResponseDisplay";

export default function NeedsSupervisorCheckbox({ caseObj }) {
  const { accessToken } = useContext(DataContext);
  const { caseDetail, caseSummaryTrigger, setCaseSummaryTrigger } =
    useContext(CaseSummaryContext);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);
  const [resolveLoading, setResolveLoading] = useState(false);
  const [needsSupervisor, setNeedsSupervisor] = useState(
    caseDetail.supervisor_needed_reason ? true : false
  );
  const [open, setOpen] = useState(false);
  const [supervisorReason, setSupervisorReason] = useState(
    caseDetail ? caseDetail.supervisor_needed_reason : ""
  );

  const handleApiError = (error) => {
    setLoading(false);
    setResolveLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr);
    setResponseSuccess(false);
    setNeedsSupervisor(caseDetail.supervisor_needed_reason ? true : false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 60000);
    return error;
  };

  const handleApiSuccess = (response, valueName) => {
    setLoading(false);
    setResolveLoading(false);
    const res = response;
    setCaseSummaryTrigger(!caseSummaryTrigger);
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setOpen(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const openModal = (e) => {
    setNeedsSupervisor(caseDetail.supervisor_needed_reason ? true : false);
    setSupervisorReason(
      caseDetail.supervisor_needed_reason
        ? caseDetail.supervisor_needed_reason
        : ""
    );
    setOpen(true);
  };

  const submitData = () => {
    setLoading(true);
    setNeedsSupervisor(true);
    const postData = {
      ...(supervisorReason === ""
        ? {}
        : { supervisor_needed_reason: supervisorReason }),
      callback: null,
    };

    axios
      .patch(`/api/case/${caseObj.pk}/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, "Needs Supervisor");
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const handleResolve = () => {
    setResolveLoading(true);
    setNeedsSupervisor(false);
    const postData = {
      supervisor_needed_reason: null,
      callback: null,
    };

    axios
      .patch(`/api/case/${caseObj.pk}/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, "Needs Supervisor");
        setSupervisorReason("");
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const handleCloseModal = () => {
    setSupervisorReason(caseDetail.supervisor_needed_reason ? true : false);
    setOpen(false);
  };

  return (
    <div>
      <div className="flex items-center">
        <FormControlLabel
          name="police_report_received"
          value={needsSupervisor}
          control={
            <Checkbox
              color="error"
              checked={needsSupervisor}
              style={{ color: "red" }}
              iconstyle={{ fill: "red" }}
            />
          }
          onChange={(e) => openModal(e)}
          label={<Typography variant="body2">Needs Supervisor</Typography>}
        />
        {loading ? <CircularProgress color="secondary" size={25} /> : ""}
      </div>
      <ResponseDisplay
        responseBreakdown={responseBreakdown}
        responseSuccess={responseSuccess}
      />
      <ModalShell
        open={open}
        setOpen={setOpen}
        title={"Supervisor Needed"}
        customCloseFunc={handleCloseModal}
        customWidth={"sm"}
        color={"#d32f2f"}
      >
        <Editor
          textareaName="content"
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          value={supervisorReason}
          onEditorChange={(newText) => {
            setSupervisorReason(newText);
          }}
          init={{
            menubar: false,
            branding: false,
            toolbar: "undo redo",
          }}
        />
        <h2 className="text-red-600 font-bold my-2 text-left">WARNING</h2>
        <p className="text-[14px]">
          By submitting this form, the client information entered here will be
          submitted to supervisors for review. Callback cadence will be removed
          and uneditable until supervisor reviews this form or Needs Supervisor
          status is resolved.
        </p>

        <div>
          <div className="mt-2">
            <FormSubmitButton
              isLoading={loading}
              buttonText={supervisorReason ? "Update" : "Submit"}
              buttonColor="red"
              handleSubmit={submitData}
            />
          </div>
          {caseDetail.supervisor_needed_reason ? (
            <div className="mt-4 w-full border-t pt-4">
              <FormSubmitButton
                isLoading={resolveLoading}
                buttonText="Resolve"
                buttonColor="sky"
                handleSubmit={handleResolve}
              />
              <p className="text-[14px]">
                Please note: Clicking above will clear all previous available
                notes and information involving this supervisor need.{" "}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </ModalShell>
    </div>
  );
}
