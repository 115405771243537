import { getColor } from "../getColor";
import FlagTooltip from "./FlagTooltip";

const Flag = ({
  fieldName,
  flagValue,
  tooltipTitle,
  tooltipDesc,
  tooltipObj,
}) => {
  const getFlagColor = (value) => {
    return getColor(value);
  };
  return (
    <div id={"Flag_" + fieldName} data-testid={"Flag_" + fieldName}>
      <FlagTooltip
        fieldName={fieldName}
        title={tooltipTitle}
        desc={tooltipDesc}
        legend={tooltipObj}
      >
        <div
          className="flag"
          style={{
            width:'0px',
            height:'0px',
            borderTop: '10px',
            borderTopStyle:'solid',
            borderTopColor: getFlagColor(flagValue),
            borderLeft: '10px solid transparent',
          }}
        >
          &nbsp;
        </div>
      </FlagTooltip>
    </div>
  );
};

export default Flag;
