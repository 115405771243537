import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const PDSeverity = ({
  fieldName,
  value,
  showFlag,
  flag,
  flagToolTipTitle,
  flagLegendObj,
  clock,
}) => {
  return (
    <BaseField
      id="SB-PD-Severity"
      testId="SB-PD-Severity"
      fieldName={fieldName}
      label={"PD-Severity"}
      showFlag={showFlag}
      flag={flag}
      flagToolTipTitle={flagToolTipTitle}
      flagLegendObj={flagLegendObj}
      clock={clock}
    >
      <span>{ValueWithFallback(value)}</span>
    </BaseField>
  );
};

export default PDSeverity;
