import { Close } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import LiensBalances from "./LiensBalances";

const LiensBalancesModal = ({ openExpense, setOpenExpense }) => {
  return (
    <Dialog open={openExpense}>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100%",
          width: "500px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton onClick={() => setOpenExpense(false)}>
            <Close />
          </IconButton>
        </div>
        <LiensBalances />
      </div>
    </Dialog>
  );
};
export default LiensBalancesModal;
