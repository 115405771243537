const LabelText = ({ label }) => {
  const getChildType = (child) => {
    return typeof child;
  };
  return (
    <>
      {getChildType(label) === "string" && (
        <div
          className="text-[10px] text-slate-500"
          style={{ lineHeight: "9px" }}
        >
          {label}
        </div>
      )}
    </>
  );
};
export default LabelText;
