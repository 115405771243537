import { format, parseISO } from "date-fns";
import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;
const birthdateValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  let trimmedValue = value.trim();
  if (trimmedValue === "") {
    return emptyString;
  }

  let date = parseISO(trimmedValue);
  return format(date, "M/d/yyyy");
};

const BirthDateModel = (date) => {
  return birthdateValue(date);
};
export default BirthDateModel;
