import React from "react";
import { PhoneDisabled } from "@mui/icons-material";
import { getColor } from "../ui/getColor";
import InfoToolTip from "../ui/InfoToolTip";
import STRINGS from "../utils/strings";
import indicatorColors from "../utils/indicatorColors";

const DoNotContact = ({ minorCheck }) => {
  const getAgeColor = (value) => {
    if(getColor(value) === indicatorColors.green){
      return indicatorColors.black;
    }
    return getColor(minorCheck);
  }
  return (
    <div id="Do-Not-Contact" data-test-id="Do-Not-Contact">
      <span style={{ color: getAgeColor(minorCheck) }}>
        <InfoToolTip
          itemList={STRINGS.DO_NOT_CONTACT}
          headerTitle={"Do Not Contact"}
          icon={<PhoneDisabled fontSize="inherit" className="w-[13px] h-[13px] m-2"/>}
        >
          <PhoneDisabled fontSize="inherit" />
        </InfoToolTip>
      </span>
    </div>
  );
};
export default DoNotContact;
