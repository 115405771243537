import InfoToolTip from "../InfoToolTip";
import { Square } from "@mui/icons-material";

const FlagTooltip = ({ fieldName, title, desc, legend, children }) => {
  return (
    <div id={"Flag-ToolTip-" + fieldName} data-testid={"Flag-ToolTip-" + fieldName}>
      <InfoToolTip
        itemList={legend}
        headerTitle={title}
        desc={desc}
        icon={<Square fontSize="small"/>}
        id={fieldName + "_tooltip"}
      >
      <span className="cursor-default">{children}</span>
      </InfoToolTip>
    </div>
  );
};

export default FlagTooltip;
