import { Cancel, Check } from "@mui/icons-material";
import React from "react";

export default function ResponseDisplay({
  responseSuccess,
  responseBreakdown,
}) {
  return (
    <p
      className="text-[12px]"
      style={{ color: responseSuccess === false ? "#d32f2f" : "" }}
    >
      {" "}
      {responseSuccess === false ? (
        <Cancel className="text-red-600 text-[16px]" />
      ) : (
        ""
      )}{" "}
      {responseBreakdown}{" "}
      {responseSuccess ? <Check className="text-green-600" /> : ""}
    </p>
  );
}
