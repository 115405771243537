import { Square } from "@mui/icons-material";
import BaseField from "../../ui/BaseField";
import InfoToolTip from "../../ui/InfoToolTip";
import ValueWithFallback from "../../ui/ValueWithFallback";
import DOAModel from "./DOAModel";

const DateOfAccident = ({
  fieldName,
  date,
  statuteOfLimitations,
  flag,
  clock,
}) => {
  return (
    <BaseField
      id="SB-DOA"
      testId="SB-DOA"
      fieldName={fieldName}
      label={"DOA"}
      flag={flag}
      clock={clock}
    >
      <InfoToolTip
        itemList={ValueWithFallback(DOAModel(statuteOfLimitations))}
        headerTitle={"Statute of Limitations is:"}
        icon={<Square fontSize="inherit" />}
        id={fieldName + "_tooltip"}
      >
        <span className="cursor-default">
          {ValueWithFallback(DOAModel(date))}
        </span>
      </InfoToolTip>
    </BaseField>
  );
};

export default DateOfAccident;
