import { IconButton, Tooltip } from "@mui/material";
import React from "react";

export default function TooltipIconButton({
  toolTipTitle,
  Icon,
  actionFunc,
  color,
  placement,
}) {
  const buttonStyle = (buttonColor) => {
    if (buttonColor) {
      return `text-${buttonColor}-600 hover:bg-${buttonColor}-700 hover:text-white`;
    }
    return "";
  };
  return (
    <Tooltip title={toolTipTitle} placement={placement ? "" : placement}>
      <IconButton
        className={`${buttonStyle(color)}`}
        onClick={(e) => actionFunc(e)}
        size="small"
      >
        {Icon}
      </IconButton>
    </Tooltip>
  );
}
