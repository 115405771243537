import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const Coverage = ({ fieldName, value, clock, flag }) => {
  return (
    <BaseField
      id="SB-Coverage"
      testId="SB-Coverage"
      fieldName={fieldName}
      label={"Coverage"}
      value={value}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(value)}
    </BaseField>
  );
};
export default Coverage;
