const STRINGS = {
  NULL:"No Selection",
  EMPTY:"",
  NA:"N/A",
  DO_NOT_CONTACT: {
    1: null,
    2: "Under 18 - Parental Consent Required",
    3: "Under 13 - Do Not Call",
  },
  RUNNING_TOTAL_NO_PERM:"For Balances Contact Representative",
  MEDICAL_LIMITS_NO_PERM:"For Policy Info Contact Representative",
  NO_NAME:"Name Not Entered",
  LAST_APPOINTMENT:"Last completed appointment with the following provider:",
  NEXT_APPOINTMENT:"Next scheduled appointment with the following provider:",
};

export default STRINGS;