const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  trailingZeroDisplay: "stripIfInteger",
});

const FormatCurrency = (value) => {
  if (formatter.format(value) === "$NaN") return value;
  return formatter.format(value);
};

export default FormatCurrency;
