import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const IncidentRole = ({ fieldName, role, flag, clock }) => {
  return (
    <BaseField
      id="SB-Role"
      testId="SB-Role"
      fieldName={fieldName}
      label={"Role"}
      flag={flag}
      clock={clock}
    >
      <span>{ValueWithFallback(role)}</span>
    </BaseField>
  );
};

export default IncidentRole;
