import { Info } from "@mui/icons-material";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../../context/DataContext";
import CaseSummaryContext from "../../context/CaseSummaryContext";
import axios from "../../../../../../../api/axios";
import HtmlTooltip from "../../../../../../../global/HtmlTooltip";
import InlineEditRow from "../../components/InlineEditRow";

export default function CoverageColumn({
  caseObj,
  displayUrl,
  editPermissions,
  viewPermissions,
}) {
  const { accessToken } = useContext(DataContext);
  const {
    coverageDetail,
    setCoverageDetail,
    coverageTrigger,
    setCoverageTrigger,
    coverageValueLabels,
    setCoverageValueLabels,
  } = useContext(CaseSummaryContext);

  const params = useParams();

  useEffect(() => {
    axios
      .get(displayUrl, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setCoverageDetail(data);
      });
  }, [setCoverageDetail, coverageTrigger, accessToken, params.id, displayUrl]);

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/clientcoverage/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setCoverageValueLabels(data);
      });
  }, [setCoverageValueLabels, accessToken]);

  const coverageInputs = [
    {
      keyVal: "coverage_status",
      type: "select",
      title: "Coverage Status",
      optionsUrl:
        "/api/field_options/?content_type=clientcoverage&field_name=coverage_status&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "coverage_note",
      type: "text",
      title: "Coverage Note",
      maxLength: 256,
    },
    {
      keyVal: "liability_request_status",
      type: "select",
      title: "Liability Request",
      optionsUrl:
        "/api/field_options/?content_type=clientcoverage&field_name=liability_request_status&ordering=order&",
      nullable: false,
    },
    {
      keyVal: "liability_status",
      type: "select",
      title: "Liability Status",
      optionsUrl:
        "/api/field_options/?content_type=clientcoverage&field_name=liability_status&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "liability_note",
      type: "text",
      title: "Liability Note",
      maxLength: 256,
    },
  ];
  return (
    <div className="p-4 border rounded-md w-fullm mb-2">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <>
          {" "}
          <div className="flex w-full justify-end">
            {coverageDetail && coverageDetail.updated_by ? (
              <HtmlTooltip
                title={
                  <div className="w-fit">
                    Updated at:{" "}
                    {new Date(coverageDetail["updated_at"]).toLocaleString()}
                    <br />
                    Updated by: {coverageDetail["updated_by"].name}
                  </div>
                }
                placement="right"
              >
                <Info className="text-[16px] text-black" />
              </HtmlTooltip>
            ) : (
              ""
            )}
          </div>
          {coverageInputs.map((key, idx) => (
            <InlineEditRow
              key={idx}
              title={key.title}
              type={key.type}
              keyVal={key.keyVal}
              caseDetail={caseObj}
              displayDetail={coverageDetail}
              apiUrl={displayUrl}
              optionsUrl={key.optionsUrl}
              trigger={coverageTrigger}
              setTrigger={setCoverageTrigger}
              valueLabels={coverageValueLabels}
              disabled={editPermissions ? false : true}
              nullable={key.nullable}
              maxLength={key.maxLength}
            />
          ))}
        </>
      )}
    </div>
  );
}
