import { createContext, useReducer } from "react";
import { initialState, staticBarReducer } from "../state/staticBarReducer";

export const StaticBarDispatchContext = createContext();
export const StaticBarStateContext = createContext();

const StaticBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(staticBarReducer, initialState);

  return (
    <StaticBarDispatchContext.Provider value={dispatch}>
      <StaticBarStateContext.Provider value={state}>
        {children}
      </StaticBarStateContext.Provider>
    </StaticBarDispatchContext.Provider>
  );
};



export default StaticBarProvider;
