import { RequestQuote } from "@mui/icons-material";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import ActionButton from "../ui/ActionButton";
import { useNavigate, useParams } from "react-router-dom";

const ViewSettlementDetail = ({ permission }) => {
  const params = useParams();
  const navigate = useNavigate();

  const navigateToDetail = () => navigate(`/settlement/${params.id}`);

  return (
    <PermissionWrapper permission={permission}>
      <ActionButton
        title={"View Settlement Detail"}
        icon={<RequestQuote />}
        action={navigateToDetail}
      />
    </PermissionWrapper>
  );
};

export default ViewSettlementDetail;
