import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import CaseSummaryContext from "../../../context/CaseSummaryContext";
import axios from "../../../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import ModalShell from "../../../../../../../../global/ModalShell";
import FormSubmitButton from "../../../../../../../../global/FormComponents/FormSubmitButton";
import ResponseDisplay from "../../../components/ResponseDisplay";

export default function AutoReferralCheckbox() {
  const { accessToken, userRoles } = useContext(DataContext);
  const { caseDetail, caseSummaryTrigger, setCaseSummaryTrigger } =
    useContext(CaseSummaryContext);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [autoRefNotes, setAutoRefNotes] =
    useState(`<ul class="ul1">\n<li class="li1">Old Vehicle Year: </li>\n<li class="li1">Old Vehicle Make: </li>\n<li class="li1">Old Vehicle Model: </li>\n<li class="li1">Was the totaled car owned outright or paid off: </li>\n<li class="li1">What's the payoff amount of the previous loan (leave blank if no previous loan): </li>\n<li class="li1">PD Amount Approved (to determine the equity in the totaled vehicle): </li>\n</ul>
  `);
  const [autoProvs, setAutoProvs] = useState([]);
  const [autoProvId, setAutoProvId] = useState();
  const [isReferred, setIsReferred] = useState(false);
  const [referredProvider, setReferredProvider] = useState(false);

  useEffect(() => {
    if (
      userRoles.permissions.includes("auto_providers.view_all_auto_providers")
    ) {
      axios
        .get(`/api/auto/providers/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setAutoProvs(response.data.results);
          return response;
        })
        .catch((err) => {
          return err;
        });
    }
  }, [accessToken, userRoles.permissions]);

  useEffect(() => {
    if (!caseDetail) {
      return;
    } else {
      axios
        .get(`/api/auto/referrals/?case=${caseDetail.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          if (response.data.count > 0) {
            setIsReferred(true);
            setReferredProvider(response.data.results[0].auto_provider.name);
          }
          return response;
        })
        .catch((err) => {
          return err;
        });
    }
  }, [caseDetail, caseSummaryTrigger, accessToken]);

  const handleApiError = (error) => {
    setLoading(false);
    setOpen(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr);
    setResponseSuccess(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 60000);
    return error;
  };

  const handleApiSuccess = (response, valueName) => {
    setLoading(false);
    setOpen(false);
    const res = response;
    setCaseSummaryTrigger(!caseSummaryTrigger);
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setOpen(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const openModal = (e) => {
    setOpen(true);
  };

  const submitData = () => {
    setLoading(true);
    const postData = {
      auto_provider: autoProvId,
      case: caseDetail.pk,
      notes: autoRefNotes,
    };

    axios
      .post(`/api/auto/referrals/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, "Auto Referral");
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="flex items-center">
        {userRoles.permissions.includes(
          "auto_providers.view_all_auto_providers"
        ) ? (
          <Tooltip
            title={
              isReferred
                ? `Client has already been reffered to ${referredProvider}`
                : "Refer client to Auto Provider"
            }
          >
            <FormControlLabel
              name="auto_referral"
              disabled={isReferred}
              control={
                <Checkbox
                  checked={isReferred}
                  iconstyle={{ fill: "#1976d2" }}
                  style={{ color: "#1976d2" }}
                />
              }
              onChange={() => openModal()}
              label={<Typography variant="body2">Refer to Auto</Typography>}
            />
          </Tooltip>
        ) : (
          ""
        )}
        {loading ? (
          <CircularProgress color="secondary" size={25} />
        ) : responseSuccess === false ? (
          ""
        ) : (
          ""
        )}
      </div>
      <ResponseDisplay
        responseBreakdown={responseBreakdown}
        responseSuccess={responseSuccess}
      />{" "}
      <ModalShell
        open={open}
        setOpen={setOpen}
        title={"Auto Referral"}
        customCloseFunc={handleCloseModal}
        color={"#1976d2"}
      >
        <div className="min-w-[320px]">
          <FormGroup className="justify-center p-0 text-sm w-full mb-2">
            <InputLabel id="auto_providers" className="text-sm">
              Auto Providers
            </InputLabel>
            <Select
              size="small"
              labelId="auto_providers"
              id="Filter"
              value={!autoProvId ? "" : autoProvId}
              label="Auto Providers"
              onChange={(e) => setAutoProvId(e.target.value)}
              className="text-sm"
              placeholder="Auto Providers"
              style={{ width: "100%" }}
            >
              {!autoProvs
                ? ""
                : autoProvs.map((item, idx) => (
                    <MenuItem
                      value={item.pk}
                      className="text-sm"
                      key={idx + Math.random()}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              <MenuItem value={null} className="text-sm">
                Remove Provider
              </MenuItem>
            </Select>
          </FormGroup>
          <Editor
            textareaName="content"
            apiKey={process.env.REACT_APP_TINY_MCE_KEY}
            value={autoRefNotes}
            onEditorChange={(newText) => {
              setAutoRefNotes(newText);
            }}
            init={{
              menubar: false,
              branding: false,
              toolbar: "undo redo",
            }}
          />
          <div>
            <div className="mt-2">
              <FormSubmitButton
                isLoading={loading}
                buttonText={"Submit"}
                buttonColor="sky"
                handleSubmit={submitData}
              />
            </div>
          </div>
        </div>
      </ModalShell>
    </div>
  );
}
