import { useCallback } from "react";
import {
  setfetchError,
  setfetchIsLoading,
  setfetchSuccess,
  setFieldData,
  setFieldDataIsLoading,
  setFieldMetaData,
  setShouldFetchData,
  setShouldFetchMetaData,
} from "../state/actions";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import { case_detail } from "../../../../../../api/endpoints/case";

export default function useAPIFieldData(caseId, accessToken, dispatch, state) {
  const fetch = useCallback(
    async (fieldOptionsUrls) => {
      return Promise.all(fieldOptionsUrls.map(async (url) => await url))
        .then((res) => res)
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setfetchError(dispatch, errArr);
        });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setfetchIsLoading(dispatch, true);
    setShouldFetchData(dispatch, false);
    const fieldOptionsUrls = [case_detail.getStaticBarTooltipData(accessToken)];

    if (state.shouldFetchTooltipData === true) {
      fetch(fieldOptionsUrls)
        .then(async (results) => {
          if (results) {
            if (results[0] && Object.keys(results[0]).length > 0) {
              return Object.keys(results[0].results).forEach((item) => {
                let newObj = {
                  fieldName: results[0].results[item].field_name,
                  value: null,
                  can_view: true,
                  clock_state: null,
                  flag_state: null,
                };
                let newUIObj = {
                  tooltips: results[0].results[item].additional_attributes,
                  isLoading:true
                };

                setFieldData(dispatch, {
                  fieldName: results[0].results[item].field_name,
                  data: newObj,
                });
                setFieldMetaData(dispatch, {
                  fieldName: results[0].results[item].field_name,
                  data: newUIObj,
                });
              });
            }
          }
        })
        .then(async (results) => {
          setShouldFetchMetaData(dispatch, false);
          return results;
        })
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setfetchError(dispatch, errArr);
          setfetchIsLoading(dispatch, false);
        });
    }
    case_detail
      .getStaticBarData(caseId, accessToken)
      .then((res) => {
        return Object.keys(res).forEach((item) => {
          setFieldDataIsLoading(dispatch, {
            fieldName: res[item].field_name,
            data: true,
          });
          setFieldData(dispatch, {
            fieldName: item,
            data: {
              fieldName: item,
              value: res[item].value,
              can_view: res[item].can_view,
              clock_state: res[item].clock_state,
              flag_state: res[item].flag_state,
            },
          });

          setFieldDataIsLoading(dispatch, {
            fieldName: item,
            data: false,
          });
          return null;
        });
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
        setfetchIsLoading(dispatch, false);
      });
  }, [caseId, accessToken, fetch, dispatch, state.shouldFetchTooltipData]);

  return [fetchData];
}
