import { MenuItem, ListItemText } from '@mui/material';
import CustomMenuItemIcon from './CustomMenuItemIcon';
import { useNavigate } from "react-router-dom";
export default function CustomMenuItem({menu}){
  const navigate = useNavigate();
return(
    <MenuItem 
      className="text-black w-100 pr-12" 
      onClick={() => navigate(menu.url)}
    >
      <CustomMenuItemIcon icon = {menu.icon}/>
      <ListItemText>{menu.title}</ListItemText>
    </MenuItem>
    );
}