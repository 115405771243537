import BaseField from "../../ui/BaseField";
import FormatPhoneNumber from "../../utils/FormatPhoneNumber";
import ValueWithFallback from "../../ui/ValueWithFallback";
import ContactControlsNoStyling from "../../../../../../../../global/ContactControlsNoStyling";
import Colors from "../../../../../../../../global/Colors";

const PhoneNumber = ({ fieldName, number, flag, clock }) => {
  return (
    <BaseField
      id="SB-Phone"
      testId="SB-Phone"
      fieldName={fieldName}
      label={null}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(FormatPhoneNumber(number))}{" "}
      {number && (
        <ContactControlsNoStyling
          number={number}
          buttonWidth={"12px"}
          smsTitle={"Text Client"}
          smsIconColor={Colors.slate[500]}
          smsBackgroundColor={Colors.white}
          phoneTitle={"Call Client"}
          phoneIconColor={Colors.slate[500]}
          phoneBackgroundColor={Colors.white}
        />
      )}
    </BaseField>
  );
};

export default PhoneNumber;
