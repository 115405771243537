import React from "react";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import IconHolder from "./IconHolder";
import { TooltipStyling } from "../utils/TooltipStyling";

const InfoToolTip = ({ id, itemList, headerTitle, desc, icon, children }) => {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  const checkIfStringType = (obj) => {
    if(obj){
      let typeCheck = Object.prototype.toString;
      if (typeCheck.call(obj) === "[object String]") {
        return true;
      }
    }
   
    return false;
  };

  return (
      <HtmlInfoTooltip
        title={
          <>
           {headerTitle && <div>{headerTitle}<hr /></div>}
           {desc && <div>{desc}<hr /></div>}
            {itemList && !checkIfStringType(itemList)
              ? Object.keys(itemList).map(
                  (item) =>
                    itemList[item] !== null && (
                      <div key={id + "_" + item} >
                        <IconHolder iconName={icon} iconColor={item} />
                        {" "}{itemList[item]}
                      </div>
                    )
                )
              : <div key={id + "_" + itemList}>{itemList}</div>}
          </>
        }
      >
        {children}
      </HtmlInfoTooltip>
  );
};

export default InfoToolTip;
