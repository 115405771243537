import React, { useContext } from "react";
import CaseSummaryContext from "../../context/CaseSummaryContext";
import InlineEditRow from "../../components/InlineEditRow";

export default function IncidentColumn({
  caseObj,
  editPermissions,
  viewPermissions,
}) {
  const {
    caseDetail,
    caseSummaryTrigger,
    setCaseSummaryTrigger,
    caseValueLabels,
  } = useContext(CaseSummaryContext);

  const incidentInputs = [
    {
      keyVal: "provider_max",
      type: "number",
      title: "Provider Max",
    },
    {
      keyVal: "moving_violation",
      type: "text",
      title: "Moving Violation",
    },
    {
      keyVal: "property_damage_severity",
      type: "select",
      title: "Property Damage Severity",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=property_damage_severity&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "property_damage_amount",
      type: "number",
      title: "Propery Damage Cost",
    },
    {
      keyVal: "police_report_number",
      type: "text",
      title: "Police Report Number",
    },
  ];
  return (
    <div className="pr-2 pl-4 border rounded-md py-4">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <>
          {" "}
          {incidentInputs.map((key, idx) => (
            <InlineEditRow
              key={idx}
              title={key.title}
              type={key.type}
              keyVal={key.keyVal}
              displayNameKey={key.displayNameKey}
              caseDetail={caseDetail}
              apiUrl={`/api/case/${caseObj.pk}/`}
              optionsUrl={key.optionsUrl}
              customLimit={key.customLimit}
              postKeyVal={key.postKey}
              trigger={caseSummaryTrigger}
              setTrigger={setCaseSummaryTrigger}
              optionsDisplayKey={key.optionsDisplayKey}
              displayDetail={caseDetail}
              valueLabels={caseValueLabels}
              disabled={editPermissions ? false : true}
              nullable={key.nullable}
            />
          ))}
        </>
      )}
    </div>
  );
}
