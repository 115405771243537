import { Add, Check, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import qs from "query-string";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../../global/ApiRequestErrorHandler";
import { truncate } from "../../../../../../../global/helpers";
import { useDebouncedEffect } from "../../../../../../../hooks/useDebounceEffect";
import CaseSummaryContext from "../../context/CaseSummaryContext";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";
import ResponseDisplay from "../../components/ResponseDisplay";

export default function InvolvedIndividuals({
  caseObj,
  editPermissions,
  viewPermissions,
}) {
  const { accessToken, setSelectedClient } = useContext(DataContext);
  const { caseSummaryTrigger, setCaseSummaryTrigger, caseDetail } =
    useContext(CaseSummaryContext);
  const [editInvolved, setEditInvolved] = useState(false);
  const [relatedCases, setRelatedCases] = useState(
    caseDetail.relatedCases ? caseDetail.relatedCases : []
  );
  const [loading, setLoading] = useState(false);
  const [caseSearchQuery, setCaseSearchQuery] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [navigateToRelatedCase, setNavigateToRelatedCase] = useState(false);
  const [caseList, setCaseList] = useState([]);
  const navigate = useNavigate();

  const handleApiError = (error) => {
    setLoading(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr);
    setResponseSuccess(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 60000);
    return error;
  };

  const handleApiSuccess = (response, valueName) => {
    setLoading(false);
    const res = response;
    setCaseSummaryTrigger(!caseSummaryTrigger);
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setEditInvolved(false);
    setCaseSearchQuery("");
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  const addCaseRelationship = () => {
    setLoading(true);
    const ids = !relatedCases ? [] : relatedCases.map(({ id, pk }) => id || pk);

    let patchedData = {
      related_cases: ids,
    };

    axios
      .patch(`/api/case/${caseObj.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, "Companions");
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const sendRequest = () => {
    axios
      .get(
        `/api/case/${caseObj.pk}/relatable_cases/?${qs.stringify(
          {
            name_search: !caseSearchQuery ? null : caseSearchQuery,
          },
          {
            skipNull: true,
          }
        )}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setCaseList(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [caseSearchQuery],
    250
  );

  const handleNavToRelated = (clientId) => {
    setNavigateToRelatedCase(true);
    axios
      .get(`/api/case/?client=${clientId}&simplified=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        const foundCasePk = data[0].pk;
        setSelectedClient(foundCasePk);
        setNavigateToRelatedCase(false);
        navigate(`/client-detail/${foundCasePk}`);
      })
      .catch((error) => {
        setNavigateToRelatedCase(false);
        handleApiError(error);
      });
  };

  return (
    <div className="m-2">
      <LoadingIndicator isLoading={navigateToRelatedCase} />
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <>
          {" "}
          {editInvolved === false ? (
            <div className="mt-4">
              <div className="flex items-center w-full justify-between">
                <h2 className="text-sm text-gray-500">Companions:</h2>
                <Tooltip title={"Update Companions"}>
                  <span>
                    <IconButton
                      onClick={() => setEditInvolved(true)}
                      disabled={editPermissions ? false : true}
                    >
                      <Add />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
              <div>
                {!caseDetail
                  ? ""
                  : !caseDetail.related_cases
                  ? ""
                  : caseDetail.related_cases.map((inv) => (
                      <Chip
                        className="text-sm flex justify-start my-1"
                        key={inv.id}
                        onClick={() => handleNavToRelated(inv.client_id)}
                        variant="outlined"
                        label={truncate(inv.client_name, 40)}
                      />
                    ))}
              </div>
            </div>
          ) : (
            <div className="relative flex items-center">
              <Autocomplete
                size="small"
                multiple
                options={!caseList ? [] : caseList.map((option) => option)}
                getOptionLabel={(option) =>
                  !option.client ? option.client_name : option.client.name
                }
                value={relatedCases}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={
                        !option.client ? option.client_name : option.client.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(e, value) => {
                  let newArr = value.filter((obj, index) => {
                    return index === value.findIndex((o) => obj.pk === o.pk);
                  });
                  setRelatedCases(newArr);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Cases"
                    onChange={(event) => setCaseSearchQuery(event.target.value)}
                  />
                )}
              />
              {loading ? (
                <div className="flex px-2 items-center">
                  <p>Loading...</p>
                  <div className="block pl-4 relative mt-2">
                    <CircularProgress
                      color="secondary"
                      sx={{
                        maxWidth: "25px",
                        maxHeight: "25px",
                      }}
                    />
                  </div>{" "}
                </div>
              ) : (
                <div className="flex items-center">
                  <Tooltip title="Sumbit">
                    <IconButton onClick={() => addCaseRelationship()}>
                      <Check className="text-green-600" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel edit">
                    <IconButton
                      onClick={() => {
                        setEditInvolved(false);
                        setRelatedCases(caseDetail.related_cases);
                      }}
                    >
                      <Close className="text-red-600" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
          <ResponseDisplay
            responseBreakdown={responseBreakdown}
            responseSuccess={responseSuccess}
          />
        </>
      )}
    </div>
  );
}
