import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import axios from "../../../../../../../../api/axios";
import generatedGitInfo from "../../../../../../../../../generatedGitInfo.json";

export default function LostStatus({
  caseDetail,
  setOpenCustomDialog,
  setResponseBreakdown,
  setResponseSuccess,
  setLoading,
}) {
  const { loggedInUser, userRoles, accessToken, setTrigger, trigger } =
    useContext(DataContext);
  const [providersNotified, setProvidersNotified] = useState(false);
  const [lawFirmNotified, setLawFirmNotified] = useState(
    loggedInUser.entity && loggedInUser.entity.entity_type_label === "Law Firm"
      ? true
      : false
  );
  const [acknowledgeAll, setAcknowledgeAll] = useState(false);
  const [lostExplanation, setLostExplanation] = useState("");
  const [lostReason, setLostReason] = useState("");
  const [lostOptions, setLostOptions] = useState([]);

  const isLawfirm =
    loggedInUser.entity && loggedInUser.entity.entity_type_label === "Law Firm";

  const handleApiError = (error) => {
    setLoading(false);
    setOpenCustomDialog(false);
    const res = !error.response ? error.response : error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setResponseBreakdown(errArr[0]);
    setResponseSuccess(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 60000);
  };

  const handleApiSuccess = (response) => {
    setLoading(false);
    const res = response;
    setTrigger(!trigger);
    setResponseBreakdown("Case marked as lost");
    setResponseSuccess(true);
    setOpenCustomDialog(false);
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
    }, 5000);
    return res;
  };

  useEffect(() => {
    if (userRoles.permissions.includes("casemanager.view_lostcaseoptions")) {
      axios
        .get(`/api/lost_case_options/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setLostOptions(data);
        });
    }
  }, [accessToken, setLostOptions, userRoles.permissions]);

  const postLostStatus = () => {
    setLoading(true);
    const postData = {
      explanation: lostExplanation,
      display_git_hash: generatedGitInfo.gitCommitHash,
      updated_by: loggedInUser.pk,
      case: caseDetail.pk,
      lost_reason: lostReason,
    };

    axios
      .post(`/api/case/${caseDetail.pk}/lost/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((res) => {
        handleApiSuccess(res);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return !caseDetail ? (
    ""
  ) : (
    <div>
      <h2 className="font-bold text-center text-[1.2rem] text-red-600">
        You are about to mark this case as lost
      </h2>
      <p className="font-bold text-center">
        By doing so, you acknowledge that:
      </p>
      <List>
        <ListItem className="justify-center">
          This case will be marked as archived and will no longer display on
          active lists unless specifically searched
        </ListItem>
        <ListItem className="justify-center">
          {!caseDetail.entity ? "Entity Unknown" : caseDetail.entity.name} will
          stop treatment for this case
        </ListItem>
        <ListItem className="justify-center">
          {!caseDetail.entity ? "Entity Unknown" : caseDetail.entity.name} will
          stop assisting with this case
        </ListItem>
      </List>
      <div>
        <div>
          <FormControlLabel
            control={<Checkbox color="success" checked={acknowledgeAll} />}
            onChange={(e) => setAcknowledgeAll(e.target.checked)}
            label="I acknowledge all of the below"
          />
        </div>
        <div>
          <FormControlLabel
            control={<Checkbox color="info" checked={providersNotified} />}
            onChange={(e) => setProvidersNotified(e.target.checked)}
            label={
              isLawfirm
                ? "I have notified In The Network staff informing them of this lost case"
                : "I have notified all medical providers to stop treatment"
            }
          />
        </div>
        {isLawfirm ? (
          ""
        ) : (
          <div>
            <FormControlLabel
              control={<Checkbox color="info" checked={lawFirmNotified} />}
              onChange={(e) => setLawFirmNotified(e.target.checked)}
              label="I have notified the law firm staff of this lost case"
            />
          </div>
        )}
        <div>
          <FormGroup className="justify-center py-4 w-full">
            <InputLabel className="text-left ">Lost Reason</InputLabel>
            <Select
              size="small"
              value={lostReason}
              onChange={(e) => setLostReason(e.target.value)}
              MenuProps={{
                sx: { height: "200px" },
              }}
            >
              {lostOptions.map((lost) => (
                <MenuItem value={lost.id}>{lost.reason}</MenuItem>
              ))}
            </Select>
          </FormGroup>
        </div>
        <div>
          <TextField
            multiline
            placeholder="Explain reason for lost case"
            value={lostExplanation}
            onChange={(e) => setLostExplanation(e.target.value)}
            variant="standard"
            className="w-full"
            error={lostExplanation.length >= 31 ? false : true}
            helperText={
              lostExplanation.length >= 31
                ? ""
                : "Must be minimum of 31 characters"
            }
          />
        </div>
        <div className="w-fit mx-auto my-2">
          <Button
            variant="outlined"
            disabled={
              acknowledgeAll &&
              lostExplanation.length >= 31 &&
              providersNotified &&
              lawFirmNotified &&
              lostReason
                ? false
                : true
            }
            onClick={postLostStatus}
          >
            Submit Lost Case
          </Button>
        </div>
      </div>
    </div>
  );
}
