import React, { useContext } from "react";
import Column from "../ui/Column";
import Liability from "../fields/Liability";
import Coverage from "../fields/Coverage";
import MaxPainLevel from "../fields/MaxPainLevel/MaxPainLevel";
import MedicalLimits from "../fields/MedicalLimits";
import RunningTotal from "../fields/RunningTotal";
import { StaticBarStateContext } from "../../context/StaticBarContext";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import NoAccessMessage from "../fields/NoAccessMessage";
import STRINGS from "../utils/strings";

const CoverageSummary = () => {
  const state = useContext(StaticBarStateContext);

  return (
    <Column>
      {state.data?.liability_status && (
        <PermissionWrapper permission={state.data?.liability_status?.can_view}>
          <Liability
            fieldName={state.data?.liability_status?.fieldName}
            value={state.data?.liability_status?.value}
            flag={state.data?.liability_status?.flag_state}
            clock={state.data?.liability_status?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.coverage_status && (
        <PermissionWrapper permission={state.data?.coverage_status?.can_view}>
          <Coverage
            fieldName={state.data?.coverage_status?.fieldName}
            value={state.data?.coverage_status?.value}
            flag={state.data?.coverage_status?.flag_state}
            clock={state.data?.coverage_status?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.max_pain_level && (
        <PermissionWrapper
          permission={
            state.data?.max_pain_level?.can_view &&
            state.data?.max_pain_body_part?.can_view
          }
        >
          <MaxPainLevel
            fieldName={state.data?.max_pain_level?.fieldName}
            painLevel={state.data?.max_pain_level?.value}
            bodyPart={state.data?.max_pain_body_part?.value}
            flag={state.data?.max_pain_level?.flag_state}
            clock={state.data?.max_pain_level?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.medical_limits && (
        <>
          <PermissionWrapper permission={state.data?.medical_limits?.can_view}>
            <MedicalLimits
              fieldName={state.data?.medical_limits?.fieldName}
              value={state.data?.medical_limits?.value}
              flag={state.data?.medical_limits?.flag_state}
              clock={state.data?.medical_limits?.clock_state}
            />
          </PermissionWrapper>
          <PermissionWrapper permission={!state.data?.medical_limits?.can_view}>
            <NoAccessMessage value={STRINGS.MEDICAL_LIMITS_NO_PERM} />
          </PermissionWrapper>
        </>
      )}
      {state.data?.running_total && (
        <>
          <PermissionWrapper permission={state.data?.running_total?.can_view}>
            <RunningTotal
              fieldName={state.data?.running_total?.fieldName}
              value={state.data?.running_total?.value}
              flag={state.data?.running_total?.flag_state}
              clock={state.data?.running_total?.clock_state}
            />
          </PermissionWrapper>
          <PermissionWrapper permission={!state.data?.running_total?.can_view}>
            <NoAccessMessage value={STRINGS.RUNNING_TOTAL_NO_PERM} />
          </PermissionWrapper>
        </>
      )}
    </Column>
  );
};

export default CoverageSummary;
