import React, { useContext } from "react";
import PoliceReportCheckbox from "./components/PoliceReportCheckbox";
import NeedsSupervisorCheckbox from "./components/NeedsSupervisorCheckbox";
import AutoReferralCheckbox from "./components/AutoReferralCheckbox";
import DataContext from "../../../../../../../../context/DataContext";

export default function ChecklistColumn({ caseObj, viewPermissions }) {
  const { userRoles } = useContext(DataContext);
  return (
    <div className="pr-2 pl-4 border rounded-md py-4 mt-2">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <>
          {userRoles.permissions.includes("auto_providers.add_autoreferral") ? (
            <AutoReferralCheckbox caseObj={caseObj} />
          ) : (
            ""
          )}
          {userRoles.permissions.includes("users.can_view_supervisor_link") ? (
            <NeedsSupervisorCheckbox caseObj={caseObj} />
          ) : (
            ""
          )}
          <PoliceReportCheckbox caseObj={caseObj} />
        </>
      )}
    </div>
  );
}
