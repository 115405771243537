import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;

const comma = (city, state) => {
  if (cityValue(city) === "" || stateValue(state) === "") {
    return emptyString;
  }
  return ",";
};

const cityValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  let trimmedValue = value.trim();
  if (trimmedValue === "") {
    return emptyString;
  }
  return trimmedValue;
};

const stateValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  let trimmedValue = value.trim();
  if (trimmedValue === "") {
    return emptyString;
  }
  return trimmedValue;
};

const separator = (city, state) => {
  if (cityValue(city) === "" || stateValue(state) === "") {
    return emptyString;
  }
  return " ";
};

const LocationModel = (city, state) => {
  return (
    cityValue(city) + comma(city, state) + separator(city, state) + stateValue(state)
  );
};

export default LocationModel;
