import { WatchLater } from "@mui/icons-material";
import ClockTooltip from "./ClockTooltip";
import { getColor } from "../getColor";

const Clock = ({
  fieldName,
  clockValue,
  tooltipTitle,
  tooltipDesc,
  tooltipObj,
}) => {
  const getClockColor = (value) => {
    return getColor(value);
  };
  return (
    <div id={"Clock_" + fieldName} data-testid={"Clock_" + fieldName}>
      <ClockTooltip
        fieldName={fieldName}
        title={tooltipTitle}
        desc={tooltipDesc}
        legend={tooltipObj}
      >
        <div
          className="text-[10px] ml-3"
          style={{ color: getClockColor(clockValue) }}
        >
          <WatchLater fontSize="inherit" />
        </div>
      </ClockTooltip>
    </div>
  );
};

export default Clock;
