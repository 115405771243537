import { Person } from "@mui/icons-material";
import BaseField from "../../ui/BaseField";
import NameModel from "./NameModel";
import ValueWithFallback from "../../ui/ValueWithFallback";

export default function Name({ fieldName, prefix, name, flag, clock }) {
  return (
    <BaseField
      id="SB-Client-Name"
      testId="SB-Client-Name"
      fieldName={fieldName}
      flag={flag}
      clock={clock}
    >
      <Person fontSize="inherit" />
      {ValueWithFallback(NameModel(prefix, name))}
    </BaseField>
  );
}
