import { FileOpen } from "@mui/icons-material";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import ActionButton from "../ui/ActionButton";

const OpenDocumentList = ({ permission, setViewDocuments }) => {
  const openDocListModal = () => {
    setViewDocuments(true);
  };
  return (
    <PermissionWrapper permission={permission}>
      <ActionButton
        title={"Open Document List"}
        icon={<FileOpen />}
        action={openDocListModal}
      />
    </PermissionWrapper>
  );
};

export default OpenDocumentList;
