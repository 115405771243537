import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";
import FormatCurrency from "../utils/FormatCurrency";

const MedicalLimits = ({
  fieldName,
  value,
  showFlag,
  flag,
  flagToolTipTitle,
  flagLegendObj,
  clock,
}) => {
  return (
    <BaseField
      id="SB-Medical-Limits"
      testId="SB-Medical-Limits"
      fieldName={fieldName}
      label={"Medical Limits"}
      showFlag={showFlag}
      flag={flag}
      flagToolTipTitle={flagToolTipTitle}
      flagLegendObj={flagLegendObj}
      clock={clock}
    >
      <span className="font-bold">
        {FormatCurrency(ValueWithFallback(value))}
      </span>
    </BaseField>
  );
};

export default MedicalLimits;
