import UpdatedAtModel from "./UpdatedAtModel";
import UpdatedByModel from "./UpdatedByModel";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "../../utils/TooltipStyling";


const UpdatedByAtTooltip = ({ id, title, date, name, children }) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  return (
    <span id={id}>
    <HtmlTooltip
        title={
          <>
            <div>{title}</div>
            <hr />
            <div>Updated By: {UpdatedByModel(name)}</div>
            <div>Updated At: {UpdatedAtModel(date)}</div>
          </>
        }
      >
        {children}
      </HtmlTooltip>
    </span>
  );
};

export default UpdatedByAtTooltip;
