import React, { useContext } from "react";
import Column from "../ui/Column";
import MedicalStatus from "../fields/MedicalStatus";
import LegalStatus from "../fields/LegalStatus";
import CaseStatus from "../fields/CaseStatus";
import AppointmentLast from "../fields/AppointmentLast";
import AppointmentNext from "../fields/AppointmentNext";
import ProvidersInNetwork from "../fields/ProvidersInNetwork";
import ProvidersOutNetwork from "../fields/ProvidersOutNetwork";
import { StaticBarStateContext } from "../../context/StaticBarContext";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";

const StatusSummary = () => {
  const state = useContext(StaticBarStateContext);
  return (
    <Column>
      {state.data?.status && (
        <PermissionWrapper permission={state.data?.status?.can_view}>
          <MedicalStatus
            fieldName={state.data?.status?.fieldName}
            value={state.data?.status?.value}
            flag={state.data?.status?.flag_state}
            clock={state.data?.status?.clock_state}
            updatedAt={state?.data?.status_updated_at?.value}
            updatedBy={state?.data?.status_updated_by?.value}
          />
        </PermissionWrapper>
      )}
      {state.data?.legal_status && (
        <PermissionWrapper permission={state.data?.legal_status?.can_view}>
          <LegalStatus
            fieldName={state.data?.legal_status?.fieldName}
            value={state.data?.legal_status?.value}
            flag={state.data?.legal_status?.flag_state}
            clock={state.data?.legal_status?.clock_state}
            updatedAt={state?.data?.legal_status_updated_at?.value}
            updatedBy={state?.data?.legal_status_updated_by?.value}
          />
        </PermissionWrapper>
      )}
      {state.data?.overall_status && (
        <PermissionWrapper permission={state.data?.overall_status?.can_view}>
          <CaseStatus
            fieldName={state.data?.overall_status?.fieldName}
            value={state.data?.overall_status?.value}
            flag={state.data?.overall_status?.flag_state}
            clock={state.data?.overall_status?.clock_state}
            updatedAt={state?.data?.overall_status_updated_at?.value}
            updatedBy={state?.data?.overall_status_updated_by?.value}
          />
        </PermissionWrapper>
      )}
      <div className="flex">
        {state.data?.latest_appointment_date && (
          <PermissionWrapper
            permission={state.data?.latest_appointment_date?.can_view}
          >
            <AppointmentLast
              fieldName={state.data?.latest_appointment_date?.fieldName}
              value={state.data?.latest_appointment_date?.value}
              providerName={state.data?.latest_provider_name?.value}
              flag={state.data?.latest_appointment_date?.flag_state}
              clock={state.data?.latest_appointment_date?.clock_state}
            />
          </PermissionWrapper>
        )}
        {state.data?.next_appointment_date && (
          <PermissionWrapper
            permission={state.data?.next_appointment_date?.can_view}
          >
            <AppointmentNext
              fieldName={state.data?.next_appointment_date?.fieldName}
              value={state.data?.next_appointment_date?.value}
              providerName={state.data?.next_provider_name?.value}
              flag={state.data?.next_appointment_date?.flag_state}
              clock={state.data?.next_appointment_date?.clock_state}
            />
          </PermissionWrapper>
        )}
      </div>
      <div className="flex">
        {state.data?.all_in_network_providers_count && (
          <PermissionWrapper
            permission={state.data?.all_in_network_providers_count?.can_view}
          >
            <ProvidersInNetwork
              fieldName={state.data?.all_in_network_providers_count?.fieldName}
              value={state.data?.all_in_network_providers_count?.value}
              flag={state.data?.all_in_network_providers_count?.flag_state}
              clock={state.data?.all_in_network_providers_count?.clock_state}
            />
          </PermissionWrapper>
        )}
        {state.data?.all_out_of_network_providers_count && (
          <PermissionWrapper
            permission={
              state.data?.all_out_of_network_providers_count?.can_view
            }
          >
            <ProvidersOutNetwork
              fieldName={
                state.data?.all_out_of_network_providers_count?.fieldName
              }
              value={state.data?.all_out_of_network_providers_count?.value}
              flag={state.data?.all_out_of_network_providers_count?.flag_state}
              clock={
                state.data?.all_out_of_network_providers_count?.clock_state
              }
            />
          </PermissionWrapper>
        )}
      </div>
    </Column>
  );
};

export default StatusSummary;
