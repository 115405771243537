import { getColor } from "../../ui/getColor";
import indicatorColors from "../../utils/indicatorColors";

const Age = ({ age, minorCheck }) => {
  
  const getAgeColor = (value) => {
    if(getColor(value) === indicatorColors.green){
      return indicatorColors.black;
    }
    return getColor(minorCheck);
  }
  return (
  <span style={{ color: getAgeColor(minorCheck) }}>{age}</span>
)};

export default Age;
