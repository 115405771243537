import { MenuList} from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomMenuItem from './CustomMenuItem';

export default function CustomMenuList({menuItems})
{
    return(
        <Paper>
            <MenuList className = "w-fit px-2 py-5">
                {menuItems.map((item) => (
                    <CustomMenuItem menu={item}/>
                ))}
            </MenuList>
        </Paper>
    );
}
