import indicatorColors from "../utils/indicatorColors";

export const getColor = (value) => {
  switch (value) {
    case 1:
      return indicatorColors.green;
    case 2:
      return indicatorColors.yellow;
    case 3:
      return indicatorColors.red;
    case null:
      return indicatorColors.black;
    default:
      return indicatorColors.black;
  }
}