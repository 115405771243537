import { useContext } from "react";
import AddLiensBalances from "../fields/AddLiensBalances";
import OpenDocumentList from "../fields/OpenDocumentList";
import UploadDocument from "../fields/UploadDocument";
import ViewSettlementDetail from "../fields/ViewSettlementDetail";
import DataContext from "../../../../../../../../context/DataContext";

const QuickActions = ({
  docNeedsType,
  setUploadDocument,
  setViewDocuments,
  setOpenExpense
}) => {
  const { userRoles } = useContext(DataContext);

  return (
    <>
      <ViewSettlementDetail
        permission={userRoles.permissions.includes(
          "provider.view_providersettlement"
        )}
      />
      <AddLiensBalances
        permission={userRoles.permissions.includes(
          "provider.view_additionalexpense"
        )}
        setOpenExpense={setOpenExpense}
      />
      <UploadDocument
        permission={userRoles.permissions.includes("filemanager.add_document")}
        docNeedsType={docNeedsType}
        setUploadDocument={setUploadDocument}
        setViewDocuments={setViewDocuments}
      />
      <OpenDocumentList
        permission={userRoles.permissions.includes("filemanager.add_document")}
        setViewDocuments={setViewDocuments}
      />
    </>
  );
};

export default QuickActions;
