import { Cancel, Check, Info } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../../context/DataContext";
import ModalShell from "../../../../../../../global/ModalShell";
import LostStatus from "./components/LostStatus";
import { useParams } from "react-router-dom";
import HtmlTooltip from "../../../../../../../global/HtmlTooltip";
import axios from "../../../../../../../api/axios";

export default function LostButton({ caseObj }) {
  const { userRoles, accessToken, trigger } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [loading, setLoading] = useState(false);
  const [lostDetails, setLostDetails] = useState("");
  const params = useParams();

  useEffect(() => {
    if (!caseObj || !caseObj.lost) {
      setLostDetails("");
      return;
    } else {
      axios
        .get(`/api/case/${caseObj.pk}/lost/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setLostDetails(response.data);
        });
    }
  }, [caseObj, accessToken, setLostDetails, params, trigger]);

  return (
    <div className="w-fit mx-auto">
      {" "}
      <div className="flex items-center">
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => setOpen(true)}
          endIcon={caseObj.lost ? <Check className="text-red-600" /> : ""}
          disabled={
            caseObj.lost ||
            !userRoles.permissions.includes("casemanager.add_loststatushistory")
              ? true
              : false
          }
        >
          Report Case Lost{" "}
        </Button>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : responseSuccess === false ? (
          <Cancel className="text-red-600" />
        ) : (
          ""
        )}
        <div className="ml-2">
          {lostDetails && lostDetails.created_by ? (
            <HtmlTooltip
              title={
                <div className="w-fit">
                  Updated at:{" "}
                  {new Date(lostDetails.created_at).toLocaleString()}
                  <br />
                  Updated by: {lostDetails.created_by.name}
                </div>
              }
              placement="right"
            >
              <Info className="text-[16px] text-black" />
            </HtmlTooltip>
          ) : (
            ""
          )}
        </div>
      </div>
      {responseSuccess === false && responseBreakdown ? (
        <p className="text-red-600 text-[12px]">{responseBreakdown}</p>
      ) : (
        ""
      )}
      {responseSuccess ? (
        <p className="text-[12px]">
          {responseBreakdown} <Check className="text-green-600" />
        </p>
      ) : (
        ""
      )}
      <ModalShell
        open={open}
        setOpen={setOpen}
        color={"#1976d2"}
        title={"Report Case Lost"}
      >
        <LostStatus
          caseDetail={caseObj}
          setOpenCustomDialog={setOpen}
          setResponseBreakdown={setResponseBreakdown}
          setResponseSuccess={setResponseSuccess}
          setLoading={setLoading}
        />
      </ModalShell>
    </div>
  );
}
