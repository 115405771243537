import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;

const nameValue = (value) => {
  if(!value && value === null) {
    return emptyString;
}
  return value;
};


const UpdatedByModel = (name) => {
  return nameValue(name);
};
export default UpdatedByModel;