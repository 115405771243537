import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const IncidentType = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-IncidentType"
      testId="SB-IncidentType"
      fieldName={fieldName}
      label={"Type"}
      value={null}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(value)}
    </BaseField>
  );
};

export default IncidentType;
