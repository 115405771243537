import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CaseSummaryContext from "../../context/CaseSummaryContext";
import DataContext from "../../../../../../../../context/DataContext";
import InlineEditRow from "../../components/InlineEditRow";
import axios from "../../../../../../../api/axios";

export default function CaseColumn({
  caseObj,
  editPermissions,
  viewPermissions,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const {
    caseDetail,
    caseSummaryTrigger,
    setCaseSummaryTrigger,
    caseValueLabels,
    caseSources,
  } = useContext(CaseSummaryContext);
  const [caseHistory, setCaseHistory] = useState({});

  const params = useParams();

  useEffect(() => {
    if (caseDetail && params.id) {
      axios
        .get(`/api/case/${params.id}/history/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setCaseHistory(data);
        });
    } else {
      return;
    }
  }, [caseSummaryTrigger, caseDetail, accessToken, params.id]);

  const caseInputs = [
    {
      keyVal: "name",
      type: "text",
      title: "Case Name",
    },
    {
      keyVal: "incident_type",
      type: "select",
      title: "Case Type",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=incident_type&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "incident_role",
      type: "select",
      title: "Incident Role",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=incident_role&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "incident_location",
      type: "search-select",
      title: "Incident State",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=incident_location&ordering=order&",
      customLimit: "100",
      postKey: "key",
      displayNameKey: "label",
    },
    {
      keyVal: "incident_city",
      type: "search-select",
      title: "Incident City",
      optionsUrl: `/api/address/cities/?state=${caseObj.incident_location}&ordering=name`,
      customDisplayUrl: true,
      optionsDisplayKey: "name",
      postKey: "name",
      disabled: caseObj.incident_location ? false : true,
    },
    {
      keyVal: "status",
      type: "search-select",
      title: "Medical Status",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=status&ordering=order&",
      postKey: "key",
      displayNameKey: "label",
      caseHistory: true,
    },
    {
      keyVal: "legal_status",
      type: "search-select",
      title: "Legal Status",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=legal_status&ordering=order&",
      postKey: "key",
      displayNameKey: "label",
      caseHistory: true,
    },
    {
      keyVal: "overall_status",
      type: "search-select",
      title: "Case Status",
      optionsUrl:
        "/api/field_options/?content_type=case&field_name=overall_status&ordering=order&",
      postKey: "key",
      displayNameKey: "label",
      caseHistory: true,
      disabled: caseObj.overall_status === "Lost" ? true : false,
    },
    {
      keyVal: "date_of_accident",
      type: "date",
      title: "Date of Incident",
    },
    {
      keyVal: "statute_of_limitations",
      type: "date",
      title: "Statute of Limitations",
    },
  ];
  return (
    <div className="pr-2 pl-4 border rounded-md py-4">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <>
          {" "}
          {caseInputs.map((key, idx) => (
            <InlineEditRow
              key={idx}
              title={key.title}
              type={key.type}
              keyVal={key.keyVal}
              displayNameKey={key.displayNameKey}
              caseDetail={caseDetail}
              apiUrl={`/api/case/${caseObj.pk}/`}
              customDisplayUrl={key.customDisplayUrl}
              optionsUrl={key.optionsUrl}
              customLimit={key.customLimit}
              postKeyVal={key.postKey}
              trigger={caseSummaryTrigger}
              setTrigger={setCaseSummaryTrigger}
              optionsDisplayKey={key.optionsDisplayKey}
              valueHistory={key.caseHistory ? caseHistory[key.keyVal] : null}
              displayDetail={caseDetail}
              valueLabels={caseValueLabels}
              disabled={key.disabled ? true : editPermissions ? false : true}
              nullable={key.nullable}
            />
          ))}
        </>
      )}

      {userRoles.permissions.includes("leads.view_campaign") ? (
        <InlineEditRow
          title={"Campaign"}
          type={"search-select"}
          keyVal={"campaign"}
          customDisplayUrl={"source"}
          displayNameKey={"name"}
          caseDetail={caseDetail}
          apiUrl={`/api/case/${caseObj.pk}/`}
          optionsUrl={"/api/leads/campaigns/?ordering=name"}
          customLimit={1000}
          postKeyVal={"pk"}
          trigger={caseSummaryTrigger}
          setTrigger={setCaseSummaryTrigger}
          displayDetail={caseDetail}
          valueLabels={caseSources}
          disabled={editPermissions ? false : true}
          nullable={false}
        />
      ) : (
        ""
      )}
    </div>
  );
}
