import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";

function ContactControlsNoStyling({
  number,
  closeModal,
  buttonWidth,
  smsTitle,
  smsIconColor,
  smsBackgroundColor,
  phoneTitle,
  phoneIconColor,
  phoneBackgroundColor,
}) {
  const [active, setActive] = useState(null);

  const dialClient = (number) => {
    const widgetToggle = document.querySelector(".Adapter_toggle");
    const widgetContainer = document.querySelector(".Adapter_root");
    if (widgetContainer.classList.contains("Adapter_minimized")) {
      widgetToggle.click();
    }
    if (closeModal) {
      closeModal();
    }

    document
      .querySelector("#rc-widget-adapter-frame")
      .contentWindow.postMessage(
        {
          type:
            active === "phone"
              ? "rc-adapter-new-call"
              : "#rc-widget-adapter-frame",
          phoneNumber: number,
          toDial: false,
          conversation: active === "phone" ? false : true, // will go to conversation page if conversation existed
        },
        "*"
      );
  };
  const handleChange = (e, newActive) => {
    setActive(newActive);
    dialClient(number);
  };
  const control = {
    value: active,
    onChange: handleChange,
    exclusive: true,
  };
  return (
    <ToggleButtonGroup className="mx-auto" size="small" {...control}>
      <Tooltip title={phoneTitle}>
        <ToggleButton
          value="phone"
          style={{
            borderColor: phoneIconColor,
            width: buttonWidth,
            height: buttonWidth,
            backgroundColor:
              active === "phone" ? phoneIconColor : phoneBackgroundColor,
            color: active === "phone" ? phoneBackgroundColor : phoneIconColor,
          }}
        >
          <PhoneIcon fontSize="inherit" />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={smsTitle}>
        <ToggleButton
          value="sms"
          style={{
            borderColor: smsIconColor,
            width: buttonWidth,
            height: buttonWidth,
            backgroundColor:
              active === "sms" ? smsIconColor : smsBackgroundColor,
            color: active === "sms" ? smsBackgroundColor : smsIconColor,
          }}
        >
          <TextsmsIcon fontSize="inherit" />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
}

export default ContactControlsNoStyling;
