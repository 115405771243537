import BaseField from "../ui/BaseField";
import UpdatedByAtTooltip from "../ui/UpdatedByAtTooltip/UpdatedByAtTooltip";
import ValueWithFallback from "../ui/ValueWithFallback";
import { Info } from "@mui/icons-material";

const CaseStatus = ({
  fieldName,
  value,
  flag,
  clock,
  updatedAt,
  updatedBy,
}) => {
  const hasPreviousHistory = (updatedAt, updatedBy) => {
    if (updatedAt === null || updatedBy === null) {
      return false;
    }
    return true;
  };
  return (
    <BaseField
      id="SB-Case-Status"
      testId="SB-Case-Status"
      fieldName={fieldName}
      label={"Case Status"}
      value={value}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(value)}{" "}
      {hasPreviousHistory(updatedAt, updatedBy) && (
        <UpdatedByAtTooltip
          id={fieldName + " updated_tooltip"}
          title={"Last Modified at"}
          date={updatedAt}
          name={updatedBy}
        >
          <span className="cursor-default text-slate-700">
            <Info fontSize="inherit" />
          </span>
        </UpdatedByAtTooltip>
      )}
    </BaseField>
  );
};

export default CaseStatus;
