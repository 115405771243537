import InjuryGraph from "./InjuryGraph";
import InjuryDetail from "./InjuryDetail";
import { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";

export default function Injuries({ caseObj }) {
  const { trigger, setTrigger } = useContext(DataContext);
  const [injuriesTrigger, setInjuriesTrigger] = useState(false);

  const setAllTriggers = () =>{
    setInjuriesTrigger(!trigger);
    setTrigger(!trigger);
  }
  return (
    <div className="h-[60vh] w-full overflow-y-auto">
      <div className="w-full">
        <InjuryDetail
          caseObj={caseObj}
          trigger={injuriesTrigger}
          setTrigger={setAllTriggers}
        />
      </div>
      <div className="w-full text-center mt-4">
        <InjuryGraph caseObj={caseObj} trigger={injuriesTrigger} />
      </div>
    </div>
  );
}
