import React, { useContext } from "react";
import DateOfAccident from "../fields/DateOfAccident/DateOfAccident";
import IncidentType from "../fields/IncidentType";
import Location from "../fields/Location/Location";
import IncidentRole from "../fields/IncidentRole";
import PDSeverity from "../fields/PDSeverity";
import Column from "../ui/Column";
import { StaticBarStateContext } from "../../context/StaticBarContext";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";

const CaseSummary = () => {
  const state = useContext(StaticBarStateContext);
  return (
    <Column>
      {state.data?.date_of_accident && state.data?.statute_of_limitations && (
        <PermissionWrapper
          permission={
            state.data?.date_of_accident.can_view &&
            state.data?.statute_of_limitations.can_view
          }
        >
          <DateOfAccident
            fieldName={state.data?.statute_of_limitations?.fieldName}
            date={state.data?.date_of_accident?.value}
            statuteOfLimitations={state.data?.statute_of_limitations?.value}
            flag={state.data?.statute_of_limitations?.flag_state}
            clock={state.data?.statute_of_limitations?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.incident_type && (
        <PermissionWrapper permission={state.data?.incident_type.can_view}>
          <IncidentType
            fieldName={state.data?.incident_type?.fieldName}
            value={state.data?.incident_type?.value}
            flag={state.data?.incident_type?.flag_state}
            clock={state.data?.incident_type?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.incident_city && (
        <PermissionWrapper permission={state.data?.incident_city?.can_view}>
          <Location
            fieldName={state.data?.incident_city?.fieldName}
            incidentCity={state.data?.incident_city?.value}
            incidentState={state.data?.incident_state?.value}
            flag={state.data?.incident_state?.flag_state}
            clock={state.data?.incident_state?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.incident_role && (
        <PermissionWrapper permission={state.data?.incident_role?.can_view}>
          <IncidentRole
            fieldName={state.data?.incident_role?.fieldName}
            role={state.data?.incident_role?.value}
            flag={state.data?.incident_role?.flag_state}
            clock={state.data?.incident_role?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.property_damage_severity && (
        <PermissionWrapper
          permission={state.data?.property_damage_severity?.can_view}
        >
          <PDSeverity
            fieldName={state.data?.property_damage_severity?.fieldName}
            value={state.data?.property_damage_severity?.value}
            flag={state.data?.property_damage_severity?.flag_state}
            clock={state.data?.property_damage_severity?.clock_state}
          />
        </PermissionWrapper>
      )}
    </Column>
  );
};

export default CaseSummary;
