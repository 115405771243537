import { Close } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ModalButton from "../../../../../global/ModalButton";
import PlacesResponseList from "../../../../../global/PlacesResponseList";
import ResponseModal from "../../../../../global/ResponseModal";

export default function CustomProvModal({ openCustomProv, setOpenCustomProv }) {
  const { accessToken } = useContext(DataContext);
  const [outOfNetworkProv, setOutOfNetworkProv] = useState("");
  const [outOfNetworkPhone, setOutOfNetworkPhone] = useState("");
  const [outOfNetworkType, setOutOfNetworkType] = useState("");
  const [provLatLng, getProvLatLng] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [address, setAddress] = useState("");
  const [provTypeOptions, setProvTypeOptions] = useState([]);

  const handlePlaceSelect = async (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => getProvLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOutOfNetworkProv("");
    setAddress("");
    setOutOfNetworkPhone("");
    setOutOfNetworkType("");
  };

  const handleNewProviderSubmit = (e) => {
    const newProvData = {
      name: outOfNetworkProv,
      type: outOfNetworkType,
      address: {
        raw: address,
        latitude: provLatLng.lat,
        longitude: provLatLng.lng,
      },
      phone: outOfNetworkPhone,
      in_network: false,
    };

    axios
      .post("/api/provider/", newProvData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        }
        setOpenError(!openError);
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.name) {
      arrayVals.push(`provider: ${errorArray.name}`);
    }

    if (errorArray.phone) {
      arrayVals.push(`phone: ${errorArray.phone}`);
    }

    if (errorArray.address) {
      for (const [key, value] of Object.entries(errorArray.address)) {
        arrayVals.push(`${key}: ${value}`);
      }
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  useEffect(() => {
    //Need to sort out why it's not reading the activeClient.client_address only here
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => getProvLatLng(latLng))
      .catch((error) => console.error("Error", error));
  }, [address]);

  useEffect(() => {
    axios
      .get(`/api/field_options/?content_type=provider&ordering=order`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setProvTypeOptions(response.data.results ?? []);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setProvTypeOptions, accessToken]);

  return (
    <Dialog open={openCustomProv}>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100%",
          width: "500px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton onClick={() => setOpenCustomProv(false)}>
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            margin: "0 0 1rem 0",
            textAlign: "center",
            color: "#9333EA",
            fontWeight: "bold",
          }}
        >
          Add Out of Network Provider
        </h1>
        <div style={{ margin: "0 2rem", width: "100%" }}>
          <form style={{ width: "100%" }}>
            <TextField
              style={{ width: "100%" }}
              required
              id="outlined-required"
              label="Out-of-Network Provider Name"
              value={outOfNetworkProv}
              onChange={(event) => {
                setOutOfNetworkProv(event.target.value);
              }}
            />
            <PlacesResponseList
              address={address}
              setAddress={setAddress}
              handlePlaceSelect={handlePlaceSelect}
              inputRequired={true}
            />
            <TextField
              style={{ width: "100%" }}
              id="outlined-required"
              label="Out-of-Network Provider Phone"
              value={outOfNetworkPhone}
              required
              onChange={(event) => {
                setOutOfNetworkPhone(event.target.value);
              }}
            />
            <div style={{ margin: "1rem 0 0" }}>
              <InputLabel id="select-provider-type" required>
                Provider Type
              </InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="select-provider-type"
                id="provider-type"
                value={outOfNetworkType}
                label="Provider Type"
                onChange={(e) => setOutOfNetworkType(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 190
                    },
                  },
                }}
              >
                {provTypeOptions.map((opt) => (
                  <MenuItem key={opt.order} value={opt.key}>
                    {opt.key}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div style={{ marginTop: "1rem" }}>
              <ModalButton
                action={handleNewProviderSubmit}
                baseColor="#9333EA"
                content="Submit Provider"
                icon={"add"}
              />
            </div>

            <ResponseModal
              title="New Provider Added"
              description={`You have successfully added "${outOfNetworkProv}" to the
          system!`}
              openBool={openSuccess}
              setOpenBool={setOpenSuccess}
              handleCloseFunc={handleOpenSuccess}
            />
            <ResponseModal
              title="Error Adding Provider"
              isError={true}
              openBool={openError}
              setOpenBool={setOpenError}
              errorMessage={errorMessage}
            />
          </form>
        </div>
      </div>
    </Dialog>
  );
}
