import { format, parseISO } from "date-fns";
import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;
const doaValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  let trimmedValue = value.trim();
  if (trimmedValue === "") {
    return emptyString;
  }

  let date = parseISO(trimmedValue);
  return format(date, "M/d/yyyy");
};
const DOAModel = (date) => {
  return doaValue(date);
};
export default DOAModel;
