import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../context/DataContext";
import CaseSummaryContext from "./context/CaseSummaryContext";
import CaseColumn from "./Sections/CaseColumn";
import ChecklistColumn from "./Sections/ChecklistColumn";
import CoverageColumn from "./Sections/CoverageColumn";
import IncidentColumn from "./Sections/IncidentColumn";
import InvolvedIndividuals from "./Sections/InvolvedIndividuals";
import LostButton from "./Sections/LostButton";
import PolicyColumn from "./Sections/PolicyColumn";
import axios from "../../../../../api/axios";

export default function CaseSummaryV2({ caseObj }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const {
    caseDetail,
    setCaseDetail,
    caseSummaryTrigger,
    setCaseValueLabels,
    setCaseSources,
  } = useContext(CaseSummaryContext);

  const params = useParams();

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/case/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setCaseValueLabels(data);
        return response;
      })
      .catch((err) => {
        return err;
      });
  }, [setCaseValueLabels, accessToken]);

  useEffect(() => {
    axios
      .get(`/api/case/${params.id}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setCaseDetail(data);
        return response;
      })
      .catch((err) => {
        return err;
      });
  }, [caseObj, setCaseDetail, caseSummaryTrigger, accessToken, params.id]);

  useEffect(() => {
    if (userRoles.permissions.includes("leads.view_campaign")) {
      axios
        .get(`/api/leads/campaigns/?limit=100`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let sourceObj = {};

          for (const item in data) {
            sourceObj[data[item].pk] = data[item].name;
          }
          setCaseSources(sourceObj);
          return response;
        })
        .catch((err) => {
          return err;
        });
    }
  }, [setCaseSources, accessToken, userRoles.permissions]);

  return !caseObj ? (
    <div>Data Not Found</div>
  ) : (
    <div className="w-auto py-1 flex flex-col justify-between md:flex-row md:m-0 m-2">
      <div className="md:w-1/3 m-2">
        <CaseColumn
          caseObj={caseDetail}
          editPermissions={userRoles.permissions.includes(
            "casemanager.change_case"
          )}
          viewPermissions={userRoles.permissions.includes(
            "casemanager.view_case"
          )}
        />
      </div>
      <div className="md:w-1/3 m-2">
        <CoverageColumn
          caseObj={caseDetail}
          displayUrl={`/api/client_coverage/${params.id}/`}
          editPermissions={userRoles.permissions.includes(
            "casemanager.change_clientcoverage"
          )}
          viewPermissions={userRoles.permissions.includes(
            "casemanager.view_clientcoverage"
          )}
        />
        <IncidentColumn
          caseObj={caseDetail}
          editPermissions={userRoles.permissions.includes(
            "casemanager.change_case"
          )}
          viewPermissions={userRoles.permissions.includes(
            "casemanager.view_case"
          )}
        />
        <ChecklistColumn
          caseObj={caseDetail}
          editPermissions={userRoles.permissions.includes(
            "casemanager.change_case"
          )}
          viewPermissions={userRoles.permissions.includes(
            "casemanager.view_case"
          )}
        />
      </div>
      <div className="md:w-1/3 m-2 h-full">
        <PolicyColumn
          caseObj={caseDetail}
          displayUrl={`/api/client_policy/${params.id}/`}
          editPermissions={userRoles.permissions.includes(
            "casemanager.change_clientpolicy"
          )}
          viewPermissions={userRoles.permissions.includes(
            "casemanager.view_clientpolicy"
          )}
        />
        <div className="flex flex-col justify-between h-full">
          <InvolvedIndividuals
            caseObj={caseDetail}
            editPermissions={userRoles.permissions.includes(
              "casemanager.change_case"
            )}
            viewPermissions={userRoles.permissions.includes(
              "casemanager.view_case"
            )}
          />
          <LostButton caseObj={caseDetail} />
        </div>
      </div>
    </div>
  );
}
