import { Language } from "@mui/icons-material";
import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const PreferredLanguage = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-Language"
      testId="SB-Language"
      fieldName={fieldName}
      flag={flag}
      clock={clock}
    >
      <Language fontSize="inherit" />
      {ValueWithFallback(value)}
    </BaseField>
  );
};

export default PreferredLanguage;
