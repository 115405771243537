import { Info } from "@mui/icons-material";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../../context/DataContext";
import CaseSummaryContext from "../../context/CaseSummaryContext";
import axios from "../../../../../../../api/axios";
import HtmlTooltip from "../../../../../../../global/HtmlTooltip";
import InlineEditRow from "../../components/InlineEditRow";

export default function PolicyColumn({
  caseObj,
  displayUrl,
  editPermissions,
  viewPermissions,
}) {
  const { accessToken } = useContext(DataContext);
  const {
    policyDetail,
    setPolicyDetail,
    policyTrigger,
    setPolicyTrigger,
    policyValueLabels,
    setPolicyValueLabels,
  } = useContext(CaseSummaryContext);

  const params = useParams();

  useEffect(() => {
    axios
      .get(displayUrl, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setPolicyDetail(data);
      });
  }, [setPolicyDetail, policyTrigger, params.id, displayUrl, accessToken]);

  useEffect(() => {
    axios
      .get(`/api/field_options/all_fields/clientpolicy/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data;
        setPolicyValueLabels(data);
      });
  }, [setPolicyValueLabels, accessToken]);

  const policyInputs = [
    {
      keyVal: "individual_policy_limit",
      type: "number",
      title: "Policy Individual",
    },
    {
      keyVal: "incident_policy_limit",
      type: "number",
      title: "Policy Incident",
    },
    {
      keyVal: "umbrella_policy_limit",
      type: "number",
      title: "Policy Limit Umbrella",
    },
    {
      keyVal: "policy_limit_selection",
      type: "select",
      title: "Policy Limit Selection",
      optionsUrl:
        "/api/field_options/?field_name=policy_limit_selection&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "uninsured_motorist_individual",
      type: "number",
      title: "UM Individual",
    },
    {
      keyVal: "uninsured_motorist_incident",
      type: "number",
      title: "UM Incident",
    },
    {
      keyVal: "uninsured_motorist_selection",
      type: "select",
      title: "UM Selection",
      optionsUrl:
        "/api/field_options/?field_name=uninsured_motorist_selection&ordering=order&",
      nullable: true,
    },
    {
      keyVal: "medical_pay",
      type: "number",
      title: "MedPay",
    },
    {
      keyVal: "personal_injury_protection",
      type: "number",
      title: "Personal Injury Protection",
    },
    {
      keyVal: "medical_limit",
      type: "number",
      title: "Medical Limits Available",
      disabled: true,
    },
  ];
  return (
    <div className="pr-2 pl-4 border rounded-md py-4">
      {!viewPermissions ? (
        <h2 className="text-center underline my-2 text-gray-500">
          Content details not available for this account
        </h2>
      ) : (
        <>
          {" "}
          <div className="flex w-full justify-end">
            {policyDetail && policyDetail.updated_by ? (
              <HtmlTooltip
                title={
                  <div className="w-fit">
                    Updated at:{" "}
                    {new Date(policyDetail["updated_at"]).toLocaleString()}
                    <br />
                    Updated by: {policyDetail["updated_by"].name}
                  </div>
                }
                placement="right"
              >
                <Info className="text-[16px] text-black" />
              </HtmlTooltip>
            ) : (
              ""
            )}
          </div>
          {policyInputs.map((key, idx) => (
            <InlineEditRow
              key={idx}
              title={key.title}
              type={key.type}
              keyVal={key.keyVal}
              caseDetail={caseObj}
              displayDetail={policyDetail}
              apiUrl={displayUrl}
              optionsUrl={key.optionsUrl}
              trigger={policyTrigger}
              setTrigger={setPolicyTrigger}
              valueLabels={policyValueLabels}
              disabled={key.disabled ? true : editPermissions ? false : true}
              nullable={key.nullable}
            />
          ))}
        </>
      )}
    </div>
  );
}
