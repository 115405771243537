export const SHOULD_FETCH_DATA = "SHOULD_FETCH_DATA";
export const FETCH_IS_LOADING = "FETCH_IS_LOADING";
export const FETCH_INPROGRESS = "FETCH_INPROGRESS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_DONE = "FETCH_DONE";
export const SET_FIELD_DATA = "SET_FIELD_DATA";
export const SET_FIELD_DATA_IS_LOADING = "SET_FIELD_DATA_IS_LOADING";
export const SET_UNVERIFIED_FLAG = "SET_UNVERIFIED_FLAG";
export const SET_FIELD_META_DATA = "SET_FIELD_META_DATA";
export const SHOULD_FETCH_META_DATA = "SHOULD_FETCH_META_DATA";
export const SET_FIELD_META_DATA_IS_LOADING = "SET_FIELD_META_DATA_IS_LOADING";

export const setShouldFetchData = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_DATA, payload: bool });

export const setfetchIsLoading = (dispatch, bool) =>
  dispatch({ type: FETCH_IS_LOADING, payload: bool });

export const setfetchInProgress = (dispatch, obj) =>
  dispatch({ type: FETCH_INPROGRESS, payload: obj });

export const setfetchSuccess = (dispatch, bool) =>
  dispatch({ type: FETCH_SUCCESS, payload: bool });

export const setfetchError = (dispatch, arr) =>
  dispatch({ type: FETCH_ERROR, payload: arr });

export const setfetchDone = (dispatch, bool) =>
  dispatch({ type: FETCH_DONE, payload: bool });

export const setFieldData = (dispatch, obj) =>
  dispatch({ type: SET_FIELD_DATA, payload: obj });

export const setFieldMetaData = (dispatch, obj) =>
  dispatch({ type: SET_FIELD_META_DATA, payload: obj });

export const setShouldFetchMetaData = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_META_DATA, payload: bool });

export const setFieldDataIsLoading = (dispatch, bool) =>
  dispatch({ type: SET_FIELD_DATA_IS_LOADING, payload: bool });

export const setUnVerifiedFlag = (dispatch, obj) =>
  dispatch({ type: SET_UNVERIFIED_FLAG, payload: obj });
