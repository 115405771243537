import React from "react";
import StaticBarProvider from "./context/StaticBarContext";
import SBar from "./components/SBar";

const StaticBar = ({
  setViewDocuments,
  setUploadDocument,
  docNeedsType,
  caseId,
  setOpenExpense
}) => {
  return (
    <div id="Static-Bar" data-testid="Static-Bar" className="mt-4">
      <StaticBarProvider>
        <SBar
          caseId={caseId}
          docNeedsType={docNeedsType}
          setUploadDocument={setUploadDocument}
          setViewDocuments={setViewDocuments}
          setOpenExpense={setOpenExpense}
        />
      </StaticBarProvider>
    </div>
  );
};

export default StaticBar;
