import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;

const period = (value) => {
  if (!value && value === null) {
    return emptyString;
  }
  if (value === "" || value === " ") {
    return emptyString;
  }
  return ".";
};

const prefixValue = (value) => {
  if(!value || value === undefined || value === null) {
    return emptyString;
}
  return value;
};

const nameValue = (value) => {
  if(!value && value === null) {
    return emptyString;
}
  return value;
};

const separator = (prefix, name) => {
  if (prefixValue(prefix) === "" || nameValue(name) === "") {
    return emptyString;
  }
  return " ";
};

const NameModel = (prefix, name) => {
  return (
    prefixValue(prefix) +
    period(prefix) +
    separator(prefix, name) +
    nameValue(name)
  );
};
export default NameModel;
