import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const Liability = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-Liability"
      testId="SB-Liability"
      fieldName={fieldName}
      label={"Liability"}
      value={value}
      flag={flag}
      clock={clock}
    >
      {ValueWithFallback(value)}
    </BaseField>
  );
};

export default Liability;
