import STRINGS from "../../utils/strings";

const emptyString = STRINGS.EMPTY;

const ageValue = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  if (value === "" || value === " ") {
    return emptyString;
  }

  return "(" + value + ")";
};
const AgeModel = (age) => {
  return ageValue(age);
};
export default AgeModel;
