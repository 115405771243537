import BaseField from "../../ui/BaseField";
import ValueWithFallback from "../../ui/ValueWithFallback";
import LocationModel from "./LocationModel";

const Location = ({ fieldName, incidentCity, incidentState, flag, clock }) => {
  return (
    <BaseField
      id="SB-Location"
      testId="SB-Location"
      fieldName={fieldName}
      label={"Location"}
      flag={flag}
      clock={clock}
    >
      <span>
        {ValueWithFallback(LocationModel(incidentCity, incidentState))}
      </span>
    </BaseField>
  );
};

export default Location;
