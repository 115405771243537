import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const NoAccessMessage = ({
  value
}) => {
  return (
    <BaseField
      id=""
      testId=""
      fieldName={""}
      label={""}
      flag={null}
      flagToolTipTitle={null}
      flagLegendObj={null}
      clock={null}
      customFontSize={"10px"}
    >
      {ValueWithFallback(value)}
    </BaseField>
  );
};
export default NoAccessMessage;
